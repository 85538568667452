'use client'
import { useTranslations } from 'next-intl'

import LinkItem from '~@Atoms/link-item/link-item'

export default function NotFound() {
  const t = useTranslations('404')

  const linkClass =
    'relative before:transition before:block before:absolute before:-bottom-3xs before:left-none before:content[""] before:w-full before:h-4xs before:bg-brand-primary transition hover:text-brand-secondary hover:before:bg-brand-secondary'

  return (
    <>
      <main id="main" role="main" tabIndex={-1}>
        <div className="my-5xl px-lg">
          <div className="text-center text-brand-primary">
            <h1 className="typeface-heading-a1-standard mb-xl">{t('title')}</h1>
            <p className="typeface-body1-standard mb-xl">{t('content')}</p>
            <div className="py-medium text-center">
              <ul>
                <li>
                  <LinkItem
                    tagElt="Link"
                    href={t('links.home.href')}
                    className={linkClass}
                  >
                    {t('links.home.label')}
                  </LinkItem>
                </li>
                <li>
                  <LinkItem
                    tagElt="Link"
                    href={t('links.whoWeAre.href')}
                    className={linkClass}
                  >
                    {t('links.whoWeAre.label')}
                  </LinkItem>
                </li>
                <li>
                  <LinkItem
                    tagElt="Link"
                    href={t('links.contact.href')}
                    className={linkClass}
                  >
                    {t('links.contact.label')}
                  </LinkItem>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
